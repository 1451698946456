import plugin from 'tailwindcss/plugin';

const iconContainer = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const variants = {
			default: {
				container: theme('colors.ui.175'),
				icon: theme('textColor.dark.secondary'),
			},
			accent: {
				container: `var(--icon-container-accent-background-color, ${theme(
					'colors.accent.100',
				)})`,
				icon: `var(--icon-container-accent-icon-color, ${theme(
					'colors.accent.100',
				)})`,
			},
			success: {
				container: theme('colors.success.100'),
				icon: theme('colors.success.100'),
			},
			error: {
				container: theme('colors.error.100'),
				icon: theme('colors.error.100'),
			},
			warning: {
				container: theme('colors.warning.100'),
				icon: theme('colors.warning.100'),
			},
			info: {
				container: theme('colors.select.100'),
				icon: theme('colors.select.100'),
			},
		};

		const sizes = {
			xs: {
				container: theme('spacing.3'),
				icon: theme('spacing.2'),
			},
			sm: {
				container: theme('spacing.5'),
				icon: theme('spacing')['3.5'],
			},
			md: {
				container: theme('spacing.9'),
				icon: theme('spacing.5'),
			},
			lg: {
				container: theme('spacing.12'),
				icon: theme('spacing.6'),
			},
			xl: {
				container: theme('spacing.16'),
				icon: theme('spacing.10'),
			},
		};

		addComponents({
			[`.${e('icon-container')}`]: {
				'--vp-icon-container-background-color':
					variants.default.container,
				'--vp-icon-container-icon-color': variants.default.icon,

				alignItems: 'center',
				borderRadius: theme('borderRadius.full'),
				display: 'flex',
				justifyContent: 'center',
				height: sizes.md.container,
				width: sizes.md.container,

				'& svg': {
					color: 'var(--vp-icon-container-icon-color)',
					height: sizes.md.icon,
					width: sizes.md.icon,
				},
			},
		});

		addComponents({
			[`.${e('icon-container-solid')}`]: {
				backgroundColor: 'var(--vp-icon-container-background-color)',

				'& svg': {
					color: theme('colors.white'),
					opacity: '1',
				},
			},
		});

		addComponents({
			[`.${e('icon-container-shaded')}`]: {
				backgroundColor:
					'color-mix(in srgb, var(--vp-icon-container-background-color), white 75%)',

				'& svg': {
					color: 'var(--vp-icon-container-icon-color)',
					opacity: '1',
				},
			},
		});

		addComponents({
			[`.${e('icon-container-elevated')}`]: {
				boxShadow: '0px 2px 1px 0px #0000001A inset',
			},
		});

		Object.keys(sizes).forEach((key) => {
			addComponents({
				[`.${e('icon-container-' + key)}`]: {
					height: sizes[key].container,
					width: sizes[key].container,

					'& svg': {
						height: sizes[key].icon,
						width: sizes[key].icon,
					},
				},
				[`.${e('icon-container-' + key + '-full')}`]: {
					'& svg': {
						height: sizes[key].container,
						width: sizes[key].container,
					},
				},
			});
		});

		Object.keys(variants).forEach((key) => {
			addComponents({
				[`.${e('icon-container-' + key)}`]: {
					'--vp-icon-container-background-color':
						variants[key].container,

					'& svg': {
						'--vp-icon-container-icon-color': variants[key].icon,

						'& text': {
							color: variants[key].icon,
						},
					},
				},
				[`.${e('icon-fill-' + key)}`]: {
					color: variants[key].icon,
				},

				[`.${e('icon-container-solid')}`]: {
					[`.${e('icon-fill-' + key)}`]: {
						color: theme('colors.white'),
					},
				},
			});
		});
	};
});

export default iconContainer;
