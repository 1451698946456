import { computed } from '@angular/core';

import {
	patchState,
	signalStore,
	withComputed,
	withMethods,
	withState,
} from '@ngrx/signals';

import { ContentHotelInterface } from '@valk-nx/core/lib/interfaces/hotels.interface';

const initialState: ContentHotelInterface = {
	GUID: '',
	address: {
		city: '',
		gps: '',
		latitude: 0,
		longitude: 0,
		postalCode: '',
		street: '',
		streetNumber: '',
		streetNumberAddition: '',
	},
	contacts: [],
	currency: '',
	name: '',
	rating: null,
	slug: '',
};

export const HotelStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),

	withComputed((state) => ({
		hotelMainContact: computed(() => {
			const { contacts, address, name } = state;
			return {
				address: address(),
				name: name(),
				contact: contacts().find((contact) => contact.type === 'main'),
			};
		}),
	})),

	withMethods((store) => ({
		updateState(data: ContentHotelInterface): void {
			patchState(store, (state) => ({ ...state, ...data }));
		},
	})),
);
