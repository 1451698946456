import plugin from 'tailwindcss/plugin';

const textBorder = plugin.withOptions(function () {
	return function ({ addComponents, e, theme }) {
		addComponents({
			[`.${e('text-shadow')}`]: {
				'text-shadow': `0px 4px 4px ${theme('textColor.dark.quaternary')}`,
			},
		});
	};
});

export default textBorder;
