import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-heading',
	template: `
		<span class="mb-6 block text-center text-2xl font-semibold">
			{{ to.label | translate }}
		</span>
	`,
	standalone: false,
})
export class HeadingComponent extends FieldType<FieldTypeConfig> {}
