import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import dayjs from 'dayjs';

import { WidgetData } from '@valk-nx/compositions/ui-booking-widget/src/lib/booking-widget.component';

import * as actions from './tag-manager.actions';
import { AvailabilityWidgetInterface } from './tag-manager.interface';

@Injectable()
export class TagManagerFacade {
	constructor(private readonly store: Store) {}

	formSubmit(subject: string) {
		this.store.dispatch(actions.formSubmit({ formSubject: subject }));
	}

	newsletterSubmit(emailAddress: string) {
		this.store.dispatch(actions.newsletterSubmit({ emailAddress }));
	}

	giftcardOrderB2B(
		emailAddress: string,
		phoneNumber: string,
		companyName: string,
	) {
		this.store.dispatch(
			actions.giftcardOrderB2B({
				emailAddress,
				phoneNumber,
				companyName,
			}),
		);
	}

	giftcardOrderB2C(
		numCards: number,
		orderValue: number,
		emailAddress: string,
		phoneNumber: string,
	) {
		this.store.dispatch(
			actions.giftcardOrderB2C({
				numCards,
				orderValue,
				emailAddress,
				phoneNumber,
			}),
		);
	}

	dealsShowAvailability(
		widgetData: WidgetData,
		hotelCode: string,
		rateCode: string,
	) {
		this.store.dispatch(
			actions.dealsShowAvailability({
				...widgetData,
				hotelCode,
				rateCode,
				daytypeBooking: this.getDayTypeToday(),
				daytypeVisit: this.getDayTypeArrival(
					widgetData.selectedArrivalDate,
				),
				leadtime: this.getNumberOfDaysToArrival(
					widgetData.selectedArrivalDate,
				),
				rooms: widgetData.rooms.map((room, index) => {
					return {
						item_name: `ROOM${index + 1}`,
						numAdults: room.adults,
						numChildren: room.children,
						numBabies: room.infants,
					};
				}),
			}),
		);
	}

	quoteRequest(
		emailAddress: string,
		phoneNumber: string,
		companyName: string,
	) {
		this.store.dispatch(
			actions.quoteRequest({ emailAddress, phoneNumber, companyName }),
		);
	}

	availabilityWidget(eventData: AvailabilityWidgetInterface) {
		this.store.dispatch(actions.availabilityWidget(eventData));
	}

	getDayTypeToday() {
		return dayjs().day() === 0 || dayjs().day() === 6
			? 'weekend'
			: 'weekday';
	}

	getDayTypeArrival(arrivalDateString: string) {
		const arrivalDay = dayjs(arrivalDateString).day();
		return arrivalDay === 5 || arrivalDay === 6 ? 'weekend' : 'weekday';
	}

	getNumberOfDaysToArrival(arrivalDateString: string) {
		return dayjs(arrivalDateString)
			.startOf('day')
			.diff(dayjs().startOf('day'), 'day');
	}
}
