import { NgClass } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MarkdownModule } from 'ngx-markdown';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';

import { SelectionCardInterface } from './selection-card.interface';

@Component({
	exportAs: 'vpRadio',
	selector: 'vp-selection-card',
	imports: [AngularSvgIconModule, MarkdownModule, NgClass, TranslatePipe],
	templateUrl: './selection-card.html',
})
export class SelectionCardComponent
	extends BaseDirective
	implements OnChanges, OnInit
{
	@Input() options: SelectionCardInterface[] = [];
	@Input() name = '';
	@Input() readonly = false;
	@Input() disabled = false;
	@Input() hasError = false;
	@Input() isValid = false;
	@Input() cardsAsMarkdown = false;
	@Input() customFormControl = new FormControl();
	@Input() grid: 'cols-1' | 'cols-2' | 'cols-3' = 'cols-2';
	@Input() initialValue = '';

	@Output() inputValue = new EventEmitter<string>();

	@ViewChildren('input') inputs: ElementRef[] = [];

	selectedOption = '';

	constructor(
		override readonly translate: TranslateService,
		readonly cd: ChangeDetectorRef,
	) {
		super(translate);
	}

	ngOnInit() {
		this.changedValue(
			this.options.find((option) => option.selected)?.inputId || '',
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		// NOTE: Reset the nativeElement when the firstChange is false and the currentValue is false
		if (
			changes['isValid'] &&
			!changes['isValid'].firstChange &&
			!changes['isValid'].currentValue
		) {
			// NOTE: bind options again because somehow the selected option is not reset in the HTML
			this.options = [...this.options];

			this.inputs.forEach((input) => {
				input.nativeElement.checked = false;
			});
			this.cd.detectChanges();
		}

		if (changes['initialValue']?.currentValue) {
			const initialValue = changes['initialValue'].currentValue;
			this.selectedOption = initialValue;
		}
	}

	changedValue(id: string): void {
		this.selectedOption = id;

		this.inputValue.emit(this.selectedOption);
		this.customFormControl.setValue(this.selectedOption);
	}
}
