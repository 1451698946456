import plugin from 'tailwindcss/plugin';

const pricing = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const variants = {
			sm: {
				centsSize: `var(--pricing-decimal-sm-font-size, ${theme('fontSize.sm')})`,
				conditionsSize: `var(--pricing-conditions-sm-font-size, ${theme('fontSize.sm')})`,
				gapX: `var(--pricing-sm-gap-x, ${theme('spacing.1')})`,
				gapY: `var(--pricing-sm-gap-y, ${theme('spacing.0')})`,
				priceSize: `var(--pricing-price-sm-font-size, ${theme('fontSize.xl')})`,
				strikedWidth: `var(--pricing-sm-striked-width, ${theme('spacing')['0.5']})`,
			},
			md: {
				centsSize: `var(--pricing-decimal-md-font-size, ${theme('fontSize.base')})`,
				conditionsSize: `var(--pricing-conditions-md-font-size, ${theme('fontSize.sm')})`,
				gapX: `var(--pricing-md-gap-x, ${theme('spacing')['1.5']})`,
				gapY: `var(--pricing-md-gap-y, ${theme('spacing.1')})`,
				priceSize: `var(--pricing-price-md-font-size, ${theme('fontSize.2xl')})`,
				strikedWidth: `var(--pricing-md-striked-width, ${theme('spacing')['0.5']})`,
			},
			lg: {
				centsSize: `var(--pricing-decimal-lg-font-size, ${theme('fontSize.xl')})`,
				conditionsSize: `var(--pricing-conditions-lg-font-size, ${theme('fontSize.base')})`,
				gapX: `var(--pricing-lg-gap-x, ${theme('spacing')['1.5']})`,
				gapY: `var(--pricing-lg-gap-y, ${theme('spacing.1')})`,
				priceSize: `var(--pricing-price-lg-font-size, ${theme('fontSize.3xl')})`,
				strikedWidth: `var(--pricing-lg-striked-width, ${theme('spacing.1')})`,
			},
			xl: {
				centsSize: `var(--pricing-decimal-xl-font-size, ${theme('fontSize.2xl')})`,
				conditionsSize: `var(--pricing-conditions-xl-font-size, ${theme('fontSize.lg')})`,
				gapX: `var(--pricing-xl-gap-x, ${theme('spacing.2')})`,
				gapY: `var(--pricing-xl-gap-y, ${theme('spacing.1')})`,
				priceSize: `var(--pricing-price-xl-font-size, ${theme('fontSize.4xl')})`,
				strikedWidth: `var(--pricing-xl-striked-width, ${theme('spacing.1')})`,
			},
		};

		addComponents({
			[`.${e('pricing')}`]: {
				'--vp-pricing-cents-font-size': variants.md.centsSize,
				'--vp-pricing-conditions-font-size': variants.md.conditionsSize,
				'--vp-pricing-gap-x': variants.md.gapX,
				'--vp-pricing-gap-y': variants.md.gapY,
				'--vp-pricing-price-font-size': variants.md.priceSize,
				'--vp-pricing-striked-width': variants.md.strikedWidth,

				alignItems: 'flex-end',
				columnGap: 'var(--vp-pricing-gap-x)',
				display: 'flex',
				width: 'fit-content',

				'&-combined': {
					display: 'flex',
					flexDirection: 'column',
					rowGap: 'var(--vp-pricing-gap-y)',
				},

				'&-value': {
					display: 'flex',
					fontSize: 'var(--vp-pricing-price-font-size)',
					fontWeight: '600',
					lineHeight: theme('lineHeight.none'),
				},

				'&-cents': {
					fontSize: 'var(--vp-pricing-cents-font-size)',
					lineHeight: theme('lineHeight.none'),
					marginLeft: `${theme('spacing.1')}`,
					marginTop: `${theme('spacing')['0.5']}`,
				},

				'&-conditions': {
					color: theme('textColor.dark.tertiary'),
					fontSize: 'var(--vp-pricing-conditions-font-size)',
					lineHeight: theme('lineHeight.relaxed'),
				},
			},
		});

		Object.keys(variants).forEach((key) => {
			const variant = variants[key];

			addComponents({
				[`.${e('pricing-' + key)}`]: {
					'--vp-pricing-cents-font-size': variant.centsSize,
					'--vp-pricing-conditions-font-size': variant.conditionsSize,
					'--vp-pricing-gap-x': variant.gapX,
					'--vp-pricing-gap-y': variant.gapY,
					'--vp-pricing-price-font-size': variant.priceSize,
					'--vp-pricing-striked-width': variant.strikedWidth,
				},
			});
		});

		addComponents({
			[`.${e('pricing-striked')}`]: {
				color: `${theme('textColor.grey.100')}`,
				position: 'relative',

				'.pricing-value': {
					fontSize: 'calc(var(--vp-pricing-price-font-size) - 2px)',
				},

				'.pricing-cents': {
					fontSize: 'calc(var(--vp-pricing-cents-font-size) - 2px)',
				},

				'&::before': {
					borderColor: 'currentColor',
					borderRadius: '3px',
					borderTop: 'var(--vp-pricing-striked-width) solid',
					content: '""',
					left: '0',
					position: 'absolute',
					right: '0',
					top: '50%',
					transform: 'rotate(-8deg)',
				},
			},
		});
	};
});

export default pricing;
