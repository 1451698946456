import { Component, Input, NgZone } from '@angular/core';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { AlertType } from './contracts/alert.types';
import { AlertComponent } from './alert.component';

@Component({
	selector: 'vp-alert-toastr',
	imports: [AlertComponent],
	template: `
		<vp-alert
			[closeable]="closeable"
			[content]="content"
			[emphasized]="emphasized"
			[hasElevation]="hasElevation"
			[isToastr]="true"
			[size]="size"
			[title]="title"
			[type]="type"
			(closeEmitter)="remove()"
		/>
	`,
})
export class AlertToastrComponent extends Toast {
	@Input() override title = '';
	@Input() content = '';
	@Input() closeable = false;
	@Input() emphasized = false;
	@Input() hasElevation = false;
	@Input() type: AlertType = 'dialog--info';
	@Input() size: 'base' | 'small' = 'base';

	constructor(
		toastrService: ToastrService,
		toastPackage: ToastPackage,
		ngZone?: NgZone,
	) {
		super(toastrService, toastPackage, ngZone);
	}
}
