import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

/**
 * Sheet component
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-sheet',
	templateUrl: './sheet.html',
	standalone: false,
})
export class SheetComponent extends BaseDirective implements OnInit {
	@Input() triggerId = '';
	@Input() showSheet = false;
	@Input() className = '';
	@Input() clickExclusives: string[] = [];
	@Input() clickInclusives: string[] = [];
	@Input() sheetWidth: 'third' | 'half' | 'quarter' = 'half';
	@Input() sheetAlignment: 'left' | 'right' = 'right';
	@Input() isLoading = true;

	@ContentChild('sheetTrigger') triggerRef!: ElementRef;
	@ViewChild('sheetContentWrapper') sheetRef!: ElementRef;
	@ViewChild('sheetHeader') headerRef!: ElementRef;

	isMobile$: Observable<boolean>;
	sheetWidthClass = '';
	sheetAlignmentClass = '';
	scrollPosition = 0;

	constructor(
		public override translate: TranslateService,
		private readonly viewport: ViewPortService,
	) {
		super(translate);
		this.isMobile$ = this.viewport.isMobile$;
	}

	ngOnInit(): void {
		this.setClasses();
	}

	show() {
		this.showSheet = true;
		this.scrollPosition = window.scrollY;
		GeneralHelper.bodyLock(true, this.scrollPosition);
	}

	hide() {
		if (this.showSheet) {
			this.showSheet = false;
			GeneralHelper.bodyLock(false, this.scrollPosition);
		}
	}

	toggle() {
		if (this.showSheet) {
			this.hide();
		} else {
			this.show();
		}
	}

	setClasses() {
		switch (this.sheetWidth) {
			case 'third':
				this.sheetWidthClass = 'w-1/3';
				break;
			case 'quarter':
				this.sheetWidthClass = 'w-1/4';
				break;
			case 'half':
				this.sheetWidthClass = 'w-1/2';
		}

		switch (this.sheetAlignment) {
			case 'left':
				this.sheetAlignmentClass = 'left-0';
				break;
			case 'right':
				this.sheetAlignmentClass = 'right-0';
		}
	}

	@HostListener('document:click', ['$event'])
	onClickSheet(e: Event) {
		const target = e.target as HTMLInputElement;
		if (
			target.id === this.triggerId ||
			(target.id && this.clickInclusives.includes(target.id))
		) {
			this.toggle();
		}
	}

	@HostListener('document:keydown', ['$event'])
	/* istanbul ignore next */
	onKeydownHandler(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.hide();
		}
	}
}
