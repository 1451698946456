import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LetDirective } from '@ngrx/component';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SheetComponent } from './sheet';
import { SheetContentDirective, SheetHeaderDirective } from './sheet.directive';

@NgModule({
	imports: [
		AngularSvgIconModule.forRoot(),
		CommonModule,
		LetDirective,
		TranslateDirective,
		TranslatePipe,
	],
	declarations: [SheetComponent, SheetContentDirective, SheetHeaderDirective],
	exports: [
		SheetComponent,
		SheetContentDirective,
		SheetHeaderDirective,
		AngularSvgIconModule,
	],
})
export class SheetModule {}
