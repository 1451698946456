import plugin from 'tailwindcss/plugin';
/**
 * Except for maxValue and the max-width logic, everything below here is copied from the container plugin https://github.com/tailwindlabs/tailwindcss-container-queries
 *
 * --------------------------------------------------------------------------------------------------------------------------------------------------------------------
 *
 * This plugin allows you to use container queries inside Tailwind CSS.
 * Learn more about container queries: https://web.dev/container-queries/ https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_containment/Container_queries
 *
 * A container query exists of two parts:
 * - The container element, annoted with the `@container` class
 * - The container query, annoted with the `@{screenSize}` class
 *
 * For example you can have the following html:
 * <div class="@container">
 * 	<div class="p-2 @md:p-4">Hello</div>
 * </div>
 *
 * This will apply the padding 2 on small "elements" and padding 4 on medium "elements".
 * The width of the container is the value that is being used here instead of your entire screen.
 * This allows you to create more flexible designs that are not dependent on the screen size.
 *
 * This plugin has been extended to also support the `max-width` property.
 * You can use this by adding a `max` key to the object in the theme.
 * For example:
 * max-xl:{ max: '50rem' }
 *
 * This will apply a max-width of 50rem to the container when the container is `xl`, in this case 50rem.
 * This will generate a @max-md class that you can use in your container queries.
 *
 * All the screen sizes are being read from the "screens" object inside Tailwind
 */

const containerQuery = plugin(function ({
	matchUtilities,
	matchVariant,
	theme,
}) {
	// Use the screen values so they are always in sync correctly
	/* istanbul ignore next */
	const values = theme('screens') ?? {};

	/* istanbul ignore next */
	function parseValue(value) {
		const numericValue =
			value.match(/^(\d+\.\d+|\d+|\.\d+)\D+/)?.[1] ?? null;
		if (numericValue === null) return null;

		return parseFloat(value);
	}

	/* istanbul ignore next */
	function getMaxValue(value) {
		if (typeof value === 'object' && value !== null && 'max' in value) {
			return value.max;
		}
		return null;
	}

	/* istanbul ignore next */
	matchUtilities(
		{
			'@container': (value, { modifier }) => {
				return {
					'container-type': value,
					'container-name': modifier,
				};
			},
		},
		{
			values: {
				DEFAULT: 'inline-size',
				normal: 'normal',
			},
			modifiers: 'any',
		},
	);

	/* istanbul ignore next */
	matchVariant(
		'@',
		(value = '', { modifier }) => {
			const maxValue = getMaxValue(value);
			const parsedValue = parseValue(maxValue ?? value);

			if (parsedValue === null) return [];

			const widthType = maxValue
				? `max-width: ${maxValue}`
				: `min-width: ${value}`;
			return `@container ${modifier ?? ''} (${widthType})`;
		},
		{
			values,
			/* istanbul ignore next */
			sort(aVariant, zVariant) {
				const a = parseFloat(aVariant.value);
				const z = parseFloat(zVariant.value);

				if (a === null || z === null) return 0;

				// Sort values themselves regardless of unit
				if (a - z !== 0) return a - z;

				const aLabel = aVariant.modifier ?? '';
				const zLabel = zVariant.modifier ?? '';

				// Explicitly move empty labels to the end
				if (aLabel === '' && zLabel !== '') {
					return 1;
				} else if (aLabel !== '' && zLabel === '') {
					return -1;
				}

				// Sort labels alphabetically in the English locale
				// We are intentionally overriding the locale because we do not want the sort to
				// be affected by the machine's locale (be it a developer or CI environment)
				return aLabel.localeCompare(zLabel, 'en', {
					numeric: true,
				});
			},
		},
	);
});

export default containerQuery;
