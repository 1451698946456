import plugin from 'tailwindcss/plugin';

const table = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const borderRadiusDefault = theme('borderRadius.none');

		const colors = {
			dark: theme('textColor.dark.primary'),
			ui25: theme('colors.ui.25'),
			ui100: theme('colors.ui.100'),
			white: theme('colors.white'),
		};

		const tableRowsClass = 'table-rows';

		addComponents({
			[`.${e('table-container')}`]: {
				backgroundColor: `var(--table-cell-color-background, ${colors.white})`,
				borderRadius: `var(--table-border-radius, ${borderRadiusDefault})`,
			},

			[`.${e('table-header')}`]: {
				backgroundColor: `var(--table-header-color-background, ${colors.ui25})`,
				borderColor: `var(--table-row-divider-color, ${colors.ui100})`,
				borderTopRightRadius: `var(--table-border-radius, ${borderRadiusDefault})`,
				borderTopLeftRadius: `var(--table-border-radius, ${borderRadiusDefault})`,
				color: `var(--table-header-color-text, ${colors.dark})`,
			},

			[`.${e(tableRowsClass)}`]: {
				borderColor: `var(--table-row-divider-color, ${colors.ui100})`,
				color: `var(--table-cell-color-text, ${colors.dark})`,
			},

			[`button.${e(tableRowsClass)}:hover`]: {
				backgroundColor: `var(--table-row-color-background-hover, ${theme('colors.ui.75')})`,
			},

			[`details.${e(tableRowsClass)}:hover`]: {
				backgroundColor: `var(--table-row-color-background-hover, ${theme('colors.ui.75')})`,
			},

			[`.${e('last-table-row')}`]: {
				borderBottomRightRadius: `var(--table-border-radius, ${borderRadiusDefault})`,
				borderBottomLeftRadius: `var(--table-border-radius, ${borderRadiusDefault})`,
			},

			[`.${e('table-row-expanded')}`]: {
				backgroundColor: `var(--table-row-expanded-color-background, ${colors.ui25})`,
				borderColor: `var(--table-row-divider-color, ${colors.ui100})`,
			},

			[`.${e('table-html-cell')} *`]: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
		});
	};
});

export default table;
