/**
 * Class representing the General helper.
 */
export class GeneralHelper {
	static getTimeStamp(): string {
		const now = new Date();
		const timeStamp = now.getTime();
		return timeStamp.toString();
	}

	static scrollToElementWithClass(
		className: string,
		parentClassSelector?: string,
		focusOn?: string | null,
	): void {
		setTimeout(() => {
			const elementArray = document.getElementsByClassName(className);

			if (elementArray.length) {
				let targetElement = elementArray[0];

				if (targetElement && parentClassSelector) {
					const parentTargetElement =
						targetElement.closest(parentClassSelector);
					if (parentTargetElement) {
						targetElement = parentTargetElement;
					}
				}

				targetElement.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});

				if (focusOn) {
					targetElement
						.querySelector<HTMLInputElement>(focusOn)
						?.focus();
				}
			}
		});
	}

	static scrollToElementWithId(
		id: string,
		offset = 0,
		shouldSmoothScroll = true,
	): void {
		const bodyPosition = document.body.getBoundingClientRect().top;
		const element = document.getElementById(id);
		let elementPosition = bodyPosition;
		/* istanbul ignore next */
		if (
			element?.offsetWidth &&
			element.offsetWidth > 0 &&
			element.offsetHeight > 0
		) {
			elementPosition = element.getBoundingClientRect().top;
		}
		const scrollPosition = elementPosition - bodyPosition - offset;
		window.scrollTo({
			behavior: shouldSmoothScroll ? 'smooth' : 'auto',
			top: scrollPosition,
		});
	}

	static toHTML(input: string): string {
		return (
			new DOMParser().parseFromString(input, 'text/html').documentElement
				.textContent || ''
		);
	}

	/* istanbul ignore next */
	static generateRandomId(): string {
		let randomID: string = this.generateRandomString();
		for (let index = 0; index < 7; index++) {
			randomID += this.generateRandomString();
			if (index < 4) {
				randomID += '-';
			}
		}
		return randomID;
	}

	/* istanbul ignore next */
	private static generateRandomString(): string {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}

	static capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	static readonly convertOccupancyToString = (occupancy: {
		adults: number;
		children: number;
		infants: number;
	}): string => {
		return `adults=${occupancy.adults}&children=${occupancy.children}&infants=${occupancy.infants}`;
	};

	static bodyLock(lock: boolean, scrollPosition: number): void {
		const body = document.body;
		if (lock) {
			body.classList.add('overflow-hidden', 'fixed', 'w-full');
			body.style.top = `-${scrollPosition}px`;
		} else {
			body.classList.remove('overflow-hidden', 'fixed', 'w-full');
			body.style.removeProperty('top');

			window.scrollTo(0, scrollPosition);
		}
	}

	static mobileBodyLock(
		lock: boolean,
		scrollPosition: number,
		retainPosition = false,
	): void {
		const body = document.body;
		if (lock) {
			body.classList.add(
				'overflow-hidden',
				'fixed',
				'w-full',
				'sm:overflow-auto',
				'sm:relative',
				'sm:w-auto',
			);
			if (retainPosition) {
				body.style.top = `-${scrollPosition}px`;
			}
		} else {
			body.classList.remove(
				'overflow-hidden',
				'fixed',
				'w-full',
				'sm:overflow-auto',
				'sm:relative',
				'sm:w-auto',
			);
			if (retainPosition) {
				body.style.removeProperty('top');
				window.scrollTo(0, scrollPosition);
			}
		}
	}

	static isIdElementInsideViewport(idName: string, offset = 0): boolean {
		const elementArray = document.getElementById(idName);

		if (!elementArray) return false;

		const bounding = elementArray.getBoundingClientRect();

		return (
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.right <= window.innerWidth &&
			bounding.bottom + offset <= window.innerHeight
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static addSuffixToKeys(data: any, integer: number | string): any {
		Object.keys(data).forEach(function (key) {
			if (data[key] && typeof data[key] === 'object') {
				GeneralHelper.addSuffixToKeys(data[key], integer);
			}
			data[key + '__' + integer] = data[key];
			delete data[key];
		});
		return data;
	}

	static getNormalizedName(name: string): string {
		return name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
	}

	static parseJsonObject<T>(jsonString: string) {
		try {
			return JSON.parse(jsonString) as T;
		} catch (_err) {
			return undefined;
		}
	}

	static stripWhitespace(str: string) {
		return str.replace(/\s+/g, ' ').trim();
	}
}
