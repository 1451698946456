<ng-content />
<div
	[ngClass]="{
		'md:grid-cols-2': grid === 'cols-2',
		'md:grid-cols-3': grid === 'cols-3',
	}"
	class="grid grid-cols-1 gap-1"
	data-test-id="selection-card"
>
	@for (option of options; track option) {
		<label
			[for]="option.inputId"
			[ngClass]="{
				'cursor-pointer': !disabled,
				'control-selection-card-selected':
					option.inputId === selectedOption ||
					(option.selected && !selectedOption),
				'cursor-not-allowed opacity-50': disabled,
				'px-7 py-6': cardsAsMarkdown,
			}"
			(keydown.enter)="changedValue(option.inputId)"
			class="control-selection-card"
			data-test-class="selection-card-option"
			tabindex="0"
		>
			@if (option.icon) {
				<svg-icon [src]="option.icon" class="icon-container" />
			}
			<div
				[ngClass]="{
					'gap-1': option.byline,
				}"
				class="flex flex-1 flex-col"
			>
				<div
					[ngClass]="{
						'font-semibold': option.byline,
					}"
					class="leading-4"
				>
					{{ option.label | translate }}
				</div>

				@if (cardsAsMarkdown) {
					<markdown [data]="option.byline" class="leading-6" />
				} @else if (option.byline) {
					<div class="leading-5">{{ option.byline | translate }}</div>
				}
			</div>
			<input
				#input
				[attr.aria-describedby]="option.inputId"
				[attr.aria-labelledby]="option.inputId"
				[attr.data-test-id]="option.inputId"
				[checked]="selectedOption === option.inputId"
				[disabled]="disabled"
				[id]="option.inputId"
				[name]="name"
				[ngClass]="{
					'!shadow-error-100': hasError && !disabled && !readonly,
					'!shadow-success-100': isValid && !disabled && !readonly,
				}"
				(change)="changedValue(option.inputId)"
				class="control-radio flex-none shrink-0 shadow-border shadow-ui-100"
				type="radio"
			/>
		</label>
	}
</div>
