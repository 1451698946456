import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-checkbox-group',
	template: `
		<div [attr.data-test-id]="field.id" class="my-3">
			<vp-checkbox-group
				[customFormControl]="formControl"
				[disabled]="to.disabled"
				[hasError]="showError"
				[horizontal]="to['horizontal']"
				[initialValue]="formControl.value"
				[inputId]="field.id"
				[isOptional]="to['optional']"
				[isRequired]="to.required"
				[isValid]="
					to['optional'] && !formControl.value
						? null
						: formControl.valid
				"
				[name]="field.key"
				[options]="to['checkboxOptions']"
				[readonly]="to.readonly"
				[shouldTranslate]="to['translate']"
				ngDefaultControl
			/>
		</div>
	`,
	standalone: false,
})
export class CheckboxGroupComponent extends FieldType {}
