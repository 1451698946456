import { Directive, ElementRef } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'img',
	standalone: false,
})
export class LazyImageLoadingDirective {
	constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
		const supports = 'loading' in HTMLImageElement.prototype;

		if (supports) {
			nativeElement.setAttribute('loading', 'lazy');
		}
	}
}
