import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum iconTypes {
	default = 'default',
	success = 'success',
	warning = 'warning',
	error = 'error',
}
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-status-header`,
	templateUrl: './status-header.html',
	standalone: false,
})
export class StatusHeaderComponent {
	@Input() icon = '';
	@Input() iconAnimationClass = '';
	@Input() iconType: iconTypes = iconTypes.default;
	@Input() ariaLabel = '';
	@Input() className = '';

	iconTypes = iconTypes;
}
