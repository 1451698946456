import plugin from 'tailwindcss/plugin';

const ribbon = plugin.withOptions(function () {
	return function ({ addComponents, e }) {
		addComponents({
			[`.${e('ribbon')}`]: {
				background: `var(--ribbon-color-background, #F7D073)`,
			},
			[`.${e('ribbon-fold')}`]: {
				borderColor: 'transparent',
				borderRightColor: `var(--ribbon-fold-color-background, #D7B055)`,
			},
		});
	};
});

export default ribbon;
