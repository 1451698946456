import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'accommodationType',
	pure: false,
	standalone: false,
})
export class AccommodationTypePipe implements PipeTransform {
	constructor(public translate: TranslateService) {}

	transform(
		types: string[] = [],
		params: { isPlural: boolean } = { isPlural: false },
	): string {
		const plural = params.isPlural ? '.plural' : '.singular';

		if (!types?.[0] || types.length !== 1) {
			return this.translate.instant(
				'global.accommodation-types.rooms' + plural,
			);
		}

		return this.translate.instant(
			'global.accommodation-types.' + types[0] + plural,
		);
	}
}
