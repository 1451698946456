import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'imageQuery',
	pure: true,
	standalone: false,
})
export class ImageQueryPipe implements PipeTransform {
	transform(
		imageUrl: string,
		size: { height: number; width: number },
	): string {
		if (!imageUrl) {
			return '';
		} else if (imageUrl.includes('assets.vandervalkonline.com')) {
			return `${imageUrl}&w=${size.width}&h=${size.height}&fmt=webp`;
		}
		return `${imageUrl}?io=transform:fill,width:${size.width},height:${size.height}&format=webp&quality=70`;
	}
}
