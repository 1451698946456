import { isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	PLATFORM_ID,
	SimpleChanges,
} from '@angular/core';

import { createPopper, Instance, Placement } from '@popperjs/core';
import { firstValueFrom, Observable } from 'rxjs';

import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { ViewPortService } from '@valk-nx/services/viewport/src/lib/viewport.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'vp-tooltip',
	templateUrl: './tooltip.component.html',
	standalone: false,
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements AfterViewInit, OnChanges, OnDestroy {
	@Input() alignment: Placement = 'top';
	@Input() showTooltip = false;
	@Input() iconSize = 'w-6';
	@Input() tooltipColor = 'text-black';
	@Input() ariaLabel = '';
	@Output() isOpen = new EventEmitter<boolean>();

	isMobile$: Observable<boolean>;

	uuid = GeneralHelper.generateRandomId();
	tooltipContentId = 'tooltip-content-' + this.uuid;
	tooltipTriggerId = 'tooltip-trigger-' + this.uuid;
	tooltipArrowId = 'tooltip-arrow-' + this.uuid;

	popperInstance!: Instance;
	popoverInstance!: HTMLElement;
	triggerInstance!: Element;
	arrowInstance!: HTMLElement;
	scrollPosition = 0;

	constructor(
		private readonly cd: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly viewport: ViewPortService,
	) {
		this.isMobile$ = this.viewport.isMobile$;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['alignment'] && !changes['alignment']['firstChange']) {
			this.updateTooltip();
		}
	}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.cd.detectChanges();

			this.popoverInstance = document.getElementById(
				this.tooltipContentId,
			) as HTMLElement;
			this.triggerInstance = document.getElementById(
				this.tooltipTriggerId,
			) as Element;
			this.arrowInstance = document.getElementById(
				this.tooltipArrowId,
			) as HTMLElement;

			this.popperInstance = createPopper(
				this.triggerInstance,
				this.popoverInstance,
				{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 8],
							},
						},
						{
							name: 'arrow',
							options: {
								element: this.arrowInstance,
								padding: 12,
							},
						},
						{ enabled: true, name: 'flip' },
					],
					placement: this.alignment,
				},
			);
		}
	}

	ngOnDestroy() {
		if (isPlatformBrowser(this.platformId)) {
			this.popperInstance.destroy();
		}
	}

	@HostListener('window:resize')
	updateTooltip() {
		this.popperInstance.setOptions({
			placement: this.alignment,
		});
		this.popperInstance.forceUpdate();
	}

	async show() {
		this.updateTooltip();
		this.showTooltip = true;
		this.isOpen.emit(true);
		this.scrollPosition = window.scrollY;
		GeneralHelper.mobileBodyLock(
			true,
			this.scrollPosition,
			await firstValueFrom(this.isMobile$),
		);
	}

	async hide() {
		this.showTooltip = false;
		this.isOpen.emit(false);
		GeneralHelper.mobileBodyLock(
			false,
			this.scrollPosition,
			await firstValueFrom(this.isMobile$),
		);
	}

	toggle() {
		if (this.showTooltip) {
			this.hide();
		} else {
			this.show();
		}
	}
}
