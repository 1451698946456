import { FormControl } from '@angular/forms';

import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormlyExtendedInterface } from './formly.interface';

/**
 * can be used in a FormlyFieldConfig expressionProperties
 * to determine if the current form control is valid
 */
export const fieldIsValid = (
	_model: unknown,
	_formState: unknown,
	fieldConfig: {
		formControl?: Pick<FormControl, 'valid'>;
	},
): boolean => !!fieldConfig?.formControl?.valid;

export class TranslateExtension {
	constructor(private readonly translate: TranslateService) {}

	/**
	 * prePopulate will update the fields with translations from ngx-translate
	 * @param { FormlyFieldConfig } field the field which need to be translated
	 */
	prePopulate(field: FormlyFieldConfig): void {
		const to: FormlyFieldProps & FormlyExtendedInterface = field?.props;

		if (!to?.translate || to._translated) {
			return;
		}

		to._translated = true;

		const translatedTexts = {};

		if (to.label) {
			translatedTexts['props.label'] = this.translate.stream(to.label);
		}
		// Optional placeholder
		if (to.placeholder) {
			translatedTexts['props.placeholder'] = this.translate.stream(
				to.placeholder,
			);
		}

		// Optional helperText
		if (to.labelExtras?.helperText) {
			translatedTexts['props.labelExtras.helperText'] =
				this.translate.stream(
					to.labelExtras.helperText,
					to.labelExtras.helperTextParams,
				);
		}

		if (
			to.validation?.messages &&
			Object.keys(to.validation.messages).length
		) {
			for (const key in to.validation.messages) {
				translatedTexts[`validation.messages.${key}`] =
					this.translate.stream(to.validation.messages[key]);
			}
		}

		if (
			field.validation?.['messages'] &&
			field.validation['params'] &&
			Object.keys(field.validation['params']).length
		) {
			Object.keys(field.validation['messages']).forEach((messageKey) => {
				translatedTexts[`validation.messages.${messageKey}`] =
					this.translate.stream(
						field.validation.messages[messageKey] as string,
						field.validation['params'],
					);
			});
		}

		if (field.validators && Object.keys(field.validators).length) {
			if (Array.isArray(field.props.options)) {
				// Optional select options
				const options = field.props.options;
				field.props.options = this.translate
					.stream(options.map((o) => o.label))
					.pipe(
						map((labels) => {
							for (const x of Object.keys(options)) {
								options[x]['label'] =
									labels[Object.keys(labels)[x]];
							}
							return options;
						}),
					);
			}
		}

		field.expressions = {
			...(field.expressions || {}),
			...translatedTexts,
		};
	}
}

/**
 * registerTranslateExtension is a function to extend formly translations
 * @param { TranslateService } translate the ngx translateService
 */
export function registerTranslateExtension(translate: TranslateService): {
	extensions: { extension: TranslateExtension; name: string }[];
	validationMessages: { name: string; message(): Observable<string> }[];
} {
	return {
		extensions: [
			{
				extension: new TranslateExtension(translate),
				name: 'translate',
			},
		],
		validationMessages: [
			getValidationMessage('required', 'global.error.required'),
			getValidationMessage('minLength', 'global.minlength-not-reached'),
			getValidationMessage('maxLength', 'global.maxlength-reached'),
			getValidationMessage('email', 'global.error.email'),
			getValidationMessage('phone', 'global.phonenumber.invalid'),
		],
	};

	function getValidationMessage(name: string, label: string) {
		return {
			name,
			message(): Observable<string> {
				return translate.stream(label);
			},
		};
	}
}
