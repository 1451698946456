import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { debounceTime } from 'rxjs';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'vpTextArea',
	host: {
		'[attr.disabled]': 'disabled || null',
		'[class.is-disabled]': 'disabled',
	},
	selector: 'vp-textarea',
	imports: [
		AngularSvgIconModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	templateUrl: './textarea.html',
})
export class TextareaComponent implements OnInit, OnChanges {
	_disabled = false;
	@Input() set disabled(disabledVal: boolean) {
		if (this.formControl) {
			if (disabledVal) {
				this.formControl.disable();
			} else {
				this.formControl.enable();
			}
		}
		this._disabled = disabledVal;
	}
	get disabled() {
		return this._disabled;
	}

	@Input() hasError?: boolean;
	@Input() isValid?: boolean;
	@Input() rows?: number;
	@Input() textareaId = '';
	@Input() placeholder = '';
	@Input() name = '';
	@Input() readonly?: boolean;
	@Input() initialValue = '';
	@Input() isOptional = false;
	@Input() autocomplete = 'on';

	@Output() emittedValue = new EventEmitter<Event>();

	@Input() formControl = new FormControl();

	constructor() {
		this.formControl.valueChanges
			.pipe(takeUntilDestroyed(), debounceTime(500))
			.subscribe((value) => {
				this.emittedValue.emit(value);
			});
	}

	ngOnInit(): void {
		this.formControl.setValue(this.initialValue);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['initialValue']) {
			this.formControl.setValue(changes['initialValue'].currentValue);
		}
	}
}
