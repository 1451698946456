import plugin from 'tailwindcss/plugin';

const button = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		const variants = {
			primary: {
				baseColor: `var(--button-primary-color, ${theme('colors.primary.100')})`,
				hoverColor: `var(--button-primary-color-hover, ${theme('colors.primary.125')})`,
				activeColor: `var(--button-primary-color-active, ${theme('colors.primary.125')})`,
				textColor: `var(--button-primary-color-text, ${theme('colors.white')})`,
				focusColor: `var(--button-primary-color-focus, ${theme('colors.black')})`,
				loadingBackgroundColor: `var(--button-primary-color-loading, ${theme('colors.primary.50')})`,
				loadingBackgroundColorBar: `var(--button-primary-color-loading-bar, ${theme('colors.white')})`,
				openLoadingBackgroundColor: `var(--button-primary-color-loading-open, ${theme('colors.primary.50')})`,
				openLoadingBackgroundColorBar: `var(--button-primary-color-loading-bar-open, ${theme('colors.primary.100')})`,
			},
			secondary: {
				baseColor: `var(--button-secondary-color, ${theme('colors.secondary.100')})`,
				hoverColor: `var(--button-secondary-color-hover, ${theme('colors.secondary.125')})`,
				activeColor: `var(--button-secondary-color-active, ${theme('colors.secondary.125')})`,
				textColor: `var(--button-secondary-color-text, ${theme('colors.white')})`,
				focusColor: `var(--button-secondary-color-focus, ${theme('colors.black')})`,
				loadingBackgroundColor: `var(--button-secondary-color-loading, ${theme('colors.secondary.75')})`,
				loadingBackgroundColorBar: `var(--button-secondary-color-loading-bar, ${theme('colors.white')})`,
				openLoadingBackgroundColor: `var(--button-secondary-color-loading-open, ${theme('colors.secondary.75')})`,
				openLoadingBackgroundColorBar: `var(--button-secondary-color-loading-bar-open, ${theme('colors.secondary.100')})`,
			},
		};

		addComponents({
			[`.${e('btn')}`]: {
				alignItems: 'center',
				borderRadius: `var(--button-border-radius, ${
					theme('spacing')['1']
				})`,
				cursor: 'pointer',
				display: 'inline-flex',
				fontFamily: `${theme('fontFamily.sans-serif')}`,
				fontSize: `${theme('fontSize.lg')}`,
				fontWeight: `${theme('fontWeight.semibold')}`,
				height: `fit-content`,
				gap: `${theme('spacing')['2']}`,
				justifyContent: 'center',
				lineHeight: `${theme('lineHeight.none')}`,
				paddingBottom: `${theme('spacing')['3.5']}`,
				paddingLeft: `${theme('spacing')['4']}`,
				paddingRight: `${theme('spacing')['4']}`,
				paddingTop: `${theme('spacing')['3.5']}`,
				position: 'relative',
				textDecorationLine: 'none',
				width: 'fit-content',
				whiteSpace: 'nowrap',

				'&:focus-visible': {
					outlineOffset: `${theme('spacing')['1']}`,
					outlineWidth: '2px',
					outlineStyle: 'solid',
				},
				'&:disabled': {
					cursor: 'default',
					opacity: `0.5`,
				},
				'& svg': {
					height: `${theme('spacing')['4']}`,
					width: `${theme('spacing')['4']}`,
				},
			},
			[`.${e('btn-radius')}`]: {
				borderRadius: `var(--button-border-radius, ${
					theme('spacing')['1']
				})`,
			},
		});

		Object.keys(variants).forEach((key) => {
			addComponents({
				[`.${e('btn-' + key)}`]: {
					'--vp-btn-color': variants[key].baseColor,
					'--vp-btn-loading-color':
						variants[key].loadingBackgroundColor,
					'--vp-btn-loading-bar-color':
						variants[key].loadingBackgroundColorBar,
					'--vp-btn-open-loading-color':
						variants[key].openLoadingBackgroundColor,
					'--vp-btn-open-loading-bar-color':
						variants[key].openLoadingBackgroundColorBar,

					backgroundColor: `var(--vp-btn-color)`,
					borderColor: `var(--vp-btn-color)`,
					borderWidth: `1px`,
					color: variants[key].textColor,

					'&:hover': {
						'--vp-btn-color': variants[key].hoverColor,
					},
					'&:active': {
						'--vp-btn-color': variants[key].activeColor,
					},
					'&:focus-visible': {
						outlineColor: variants[key].focusColor,
					},
					'&:visited': {
						color: variants[key].textColor,
					},

					// Correct colors for loading on outline and text
					'&.btn-outline, &.btn-text': {
						'--vp-btn-loading-color':
							'var(--vp-btn-open-loading-color)',
						'--vp-btn-loading-bar-color':
							'var(--vp-btn-open-loading-bar-color)',
					},
				},
			});
		});

		addComponents({
			[`.${e('btn-outline')}`]: {
				backgroundColor: `rgba(0,0,0,0)`,
				color: `var(--vp-btn-color)`,

				'&:visited': {
					color: `var(--vp-btn-color)`,
				},
			},
		});

		addComponents({
			[`.${e('btn-text')}`]: {
				backgroundColor: `rgba(0,0,0,0)`,
				borderColor: `rgba(0,0,0,0)`,
				color: `var(--vp-btn-color)`,

				'&:visited': {
					color: `var(--vp-btn-color)`,
				},
			},
		});

		addComponents({
			[`.${e('btn-sm')}`]: {
				fontSize: `${theme('fontSize.base')}`,
				gap: `${theme('spacing')['1.5']}`,
				padding: `${theme('spacing')['2.5']} ${theme('spacing.3')}`,

				'& svg': {
					height: `${theme('spacing')['3']}`,
					width: `${theme('spacing')['3']}`,
				},
			},
		});

		addComponents({
			[`.${e('btn-is-loading')}`]: {
				'@keyframes loading': {
					'0%': {
						left: 'calc(0% - (100% / 3))',
					},
					'100%': {
						left: 'calc(100% + (100% / 3))',
					},
				},
				overflow: 'hidden',

				'&:before': {
					backgroundColor: 'var(--vp-btn-loading-color)',
					borderRadius: `var(--button-border-radius-loading, ${
						theme('spacing')['1']
					})`,
					bottom: '1px',
					content: '""',
					height: '4px',
					left: '1px',
					position: 'absolute',
					width: 'calc(100% - 2px)',
				},
				'&:after': {
					animation: 'loading 1s infinite',
					backgroundColor: 'var(--vp-btn-loading-bar-color)',
					bottom: '1px',
					borderRadius: `var(--button-border-radius-loading, ${
						theme('spacing')['1']
					})`,
					content: '""',
					height: '4px',
					left: '1px',
					position: 'absolute',
					width: 'calc(100% / 3)',
				},
			},
		});
	};
});

export default button;
