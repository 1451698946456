/* istanbul ignore next */

import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { type ClassValue, clsx } from 'clsx';
import { BehaviorSubject } from 'rxjs';
import { twMerge } from 'tailwind-merge';

import buildInfo from '@valk-nx/build-info.json';

export const languages = ['nl', 'ca', 'en', 'es', 'de', 'fr'] as const;
export const LANGUAGE = new InjectionToken<string>('valk-language');
export const ASSETS_ROOT = new InjectionToken<string>('valk-core');
export const HOTEL_SLUG = new InjectionToken<string[]>('hotel-slug');
export const MAPS_SERVICE_KEY = new InjectionToken<string>('maps-service-key');
export const RECAPTCHA_V2_SITE_KEY = new InjectionToken<string>(
	'recaptcha-v2-site-key',
);

/* istanbul ignore next */
export function createTranslateLoader(
	http: HttpClient,
	assetsRoot: string,
): TranslateHttpLoader {
	return new TranslateHttpLoader(
		http,
		`${assetsRoot}/i18n/`,
		`.json?v=${buildInfo?.version || 1}`,
	);
}

export const fallbackLanguage = languages[0];
export type Language = (typeof languages)[number];
export type LanguageRecord = Record<Language, string>;

export const languagesLabels: LanguageRecord = {
	ca: 'Catalunya',
	de: 'Deutsch',
	en: 'English',
	es: 'Español',
	fr: 'Français',
	nl: 'Nederlands',
};
export const i18nLanguageLabels: LanguageRecord = {
	ca: 'global.spanish',
	de: 'global.german',
	en: 'global.english',
	es: 'global.spanish',
	fr: 'global.french',
	nl: 'global.dutch',
};
export const minimumSpendableLoyalAmount = 250;

export type LinkTarget = '_blank' | '_self' | '';

export const negativeHeader$: BehaviorSubject<boolean> =
	new BehaviorSubject<boolean>(true);

export interface SuccessResponse {
	succes: boolean;
}

export const LOADING = 'loading';
export const SUCCESS = 'success';
export const FAILED = 'failed';
export const CANCELLED = 'cancelled';
export const UNAUTHORIZED = 'unauthorized';
export const FORBIDDEN = 'forbidden';

export type RequestStatus = typeof LOADING | typeof SUCCESS | typeof FAILED;

/* istanbul ignore next */
export function cn(...inputs: ClassValue[]) {
	// twMerge merges tailwind classes that are in the same "group".
	// This results in always having the same output regardless of the order of the classes being generated by tailwind when developing
	// clsx is a utility for conditionally joining class names together
	// combining these two utilities allows us to have a consistent class name output, used a lot by developers also using tailwind
	return twMerge(clsx(inputs));
}
