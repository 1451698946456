import plugin from 'tailwindcss/plugin';

const pagination = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		addComponents({
			[`.${e('pagination-item')}`]: {
				borderRadius: `var(--table-border-radius, ${theme('borderRadius.none')})`,

				'&:focus-visible': {
					outlineStyle: 'solid',
					outlineWidth: '2px',
				},

				'&:hover': {
					backgroundColor: theme('colors.ui.50'),
				},

				'&.page-active': {
					backgroundColor: `var(--button-primary-color, ${theme('colors.primary.100')})`,
					color: theme('colors.white'),
				},
			},
			[`.${e('pagination-button')}`]: {
				borderRadius: `var(--table-border-radius, ${theme('borderRadius.none')})`,

				'&:focus-visible': {
					outlineStyle: 'solid',
					outlineWidth: '2px',
				},

				'&:hover': {
					backgroundColor: theme('colors.ui.50'),
				},
			},
		});
	};
});

export default pagination;
