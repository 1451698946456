import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';

import { LabelPosition } from '../label.interface';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: `vp-label`,
	templateUrl: './label.html',
	imports: [CommonModule],
})
export class LabelComponent implements OnInit {
	@Input() position: LabelPosition = LabelPosition.TopAlign;
	@Input() mobilePosition: LabelPosition = LabelPosition.TopAlign;
	@Input({ required: true }) labelText!: string;
	@Input() requiredHelperText = '';
	@Input() helperText = '';
	@Input({ required: true }) inputName!: string;
	@Input() id = '';
	@Input() required = false;
	@Input() labelWidth = '';
	@Input() contentWidth = '';
	@Input() showError = false;
	@Input() truncate = true;
	@Input() useSpanEl = false;

	identifier = '';

	ngOnInit() {
		this.identifier = this.id || this.inputName;
		if (!this.labelWidth) {
			this.labelWidth = 'w-1/4';
			if (
				this.mobilePosition !== 'left-aligned' &&
				this.mobilePosition !== 'top-left-aligned'
			) {
				this.labelWidth = 'w-full sm:w-1/4';
			}
		}

		if (!this.contentWidth) {
			this.contentWidth = 'w-3/4';
			if (
				this.mobilePosition !== 'left-aligned' &&
				this.mobilePosition !== 'top-left-aligned'
			) {
				this.contentWidth = 'w-full sm:w-3/4';
			}
		}
	}
}
