/* istanbul ignore file */
import plugin from 'tailwindcss/plugin';

const breakpoint = plugin.withOptions(function () {
	return function ({ addComponents, theme }) {
		const env = process.env?.ENVIRONMENT || '';

		// Do not add breakpoint plugin in production, uat or storybook environments
		if (
			env === 'production' ||
			env === 'storybook' ||
			env.startsWith('uat')
		) {
			return;
		}

		// Function to extract pixel value as a number
		const getPxValue = (value) => parseInt(value.replace('px', ''));

		// Sort the screens based on pixel values to make sure the order is correct
		const screens = Object.entries(theme('screens'))
			.filter(([key]) => !key.startsWith('max'))
			.sort(
				([, valueA], [, valueB]) =>
					getPxValue(valueA) - getPxValue(valueB),
			) // Sort based on pixel values
			.reduce((acc, [key, value]) => {
				acc[key] = value;
				return acc;
			}, {});

		const component = {
			'body::after': {
				position: 'fixed',
				content: '""',
				zIndex: '2147483647',
				display: 'block',
				bottom: '2.5rem',
				right: '2.5rem',
				backgroundColor: '#374151',
				color: '#f9fafb',
				padding: '4px 8px',
				borderRadius: '12px',
				fontSize: '14px',
				lineHeight: '20px',
				fontWeight: '500',
				fontFamily:
					'ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"',
			},
		};

		const buildContent = (key, value) => `"${key} > ${value}"`;

		component['@media (min-width: 0)'] = {
			'body::after': {
				content: buildContent('base', '0px'),
			},
		};

		Object.entries(screens).forEach(([key, value]) => {
			component[`@screen ${key}`] = {
				'body::after': {
					content: buildContent(key, value),
				},
			};
		});

		addComponents(component);
	};
});

export default breakpoint;
