@if (!useSpanEl) {
	<label
		[for]="identifier"
		[ngClass]="[
			position === 'left-aligned' ||
			position === 'aligned-space-between' ||
			position === 'top-left-aligned'
				? 'block sm:flex'
				: 'block',
			position === 'aligned-space-between' ? 'justify-between' : '',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'aligned-space-between' ||
			mobilePosition === 'top-left-aligned'
				? 'flex'
				: 'block',
		]"
	>
		<ng-container [ngTemplateOutlet]="labelContent" />
	</label>
}
@if (useSpanEl) {
	<span
		[ngClass]="[
			position === 'left-aligned' ||
			position === 'aligned-space-between' ||
			position === 'top-left-aligned'
				? 'block sm:flex'
				: 'block',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'aligned-space-between' ||
			mobilePosition === 'top-left-aligned'
				? 'flex'
				: 'block',
		]"
	>
		<ng-container [ngTemplateOutlet]="labelContent" />
	</span>
}
<div class="flex">
	@if (
		position === 'left-aligned' ||
		position === 'aligned-space-between' ||
		position === 'top-left-aligned'
	) {
		<div
			[class]="labelWidth + ' mr-3'"
			[ngClass]="[
				(position === 'left-aligned' ||
					position === 'aligned-space-between' ||
					position === 'top-left-aligned') &&
				mobilePosition !== 'left-aligned' &&
				mobilePosition !== 'top-left-aligned'
					? 'hidden sm:block'
					: '',
			]"
		></div>
	}
	<div [class]="contentWidth">
		@if (showError) {
			<div class="mt-1">
				<ng-content select="vp-error" />
			</div>
		}
		@if (!!helperText) {
			<div
				[ngClass]="{ 'mt-1': !showError }"
				class="text-sm text-dark-tertiary first:mt-1"
				data-test-id="helper-text"
			>
				{{ helperText }}
			</div>
		}
	</div>
</div>

<ng-template #labelContent>
	<div
		[id]="inputName + '-label-block'"
		[ngClass]="[
			position === 'left-aligned' ||
			position === 'aligned-space-between' ||
			position === 'top-left-aligned'
				? 'mr-3 w-full ' + labelWidth + ' sm:flex-col'
				: position === 'top-aligned-inline'
					? ''
					: 'flex-col items-baseline',
			position === 'left-aligned'
				? 'sm:justify-center'
				: position === 'top-aligned-inline'
					? 'items-center'
					: '',
			position === 'left-aligned' || position === 'top-left-aligned'
				? 'items-center sm:items-end'
				: '',
			position === 'aligned-space-between'
				? 'items-center sm:items-start'
				: '',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'aligned-space-between'
				? 'justify-center'
				: '',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'top-left-aligned'
				? 'flex-col !items-end'
				: '',
			mobilePosition === 'aligned-space-between'
				? 'flex-col !items-start'
				: '',
		]"
		class="flex min-w-0 max-w-full flex-wrap gap-x-1"
	>
		<div
			[id]="inputName + '-label'"
			[ngClass]="[
				position === 'left-aligned' ||
				position === 'aligned-space-between' ||
				position === 'top-left-aligned'
					? 'text-left sm:mx-0 sm:text-right'
					: 'text-left',
				mobilePosition === 'left-aligned' ||
				mobilePosition === 'aligned-space-between' ||
				mobilePosition === 'top-left-aligned'
					? 'mx-0 text-right'
					: 'text-left',
				truncate
					? 'overflow-x-clip text-ellipsis whitespace-nowrap'
					: '',
			]"
			class="max-w-full text-base font-semibold"
			data-test-id="label-text"
		>
			{{ labelText }}
		</div>
		@if (requiredHelperText) {
			<div
				[ngClass]="[
					position === 'left-aligned' ||
					position === 'aligned-space-between' ||
					position === 'top-left-aligned'
						? 'text-left sm:mx-0 sm:text-right'
						: 'text-left',
					position === 'top-aligned-inline' ? 'mt-px' : '',
					mobilePosition === 'left-aligned' ||
					mobilePosition === 'aligned-space-between' ||
					mobilePosition === 'top-left-aligned'
						? 'mx-0 text-right'
						: 'text-left',
					position === 'left-aligned' ||
					position === 'aligned-space-between'
						? '!leading-none'
						: '',
					truncate
						? 'overflow-x-clip text-ellipsis whitespace-nowrap'
						: '',
				]"
				class="max-w-full text-sm text-dark-tertiary"
				data-test-id="required-helper-text"
			>
				{{ requiredHelperText }}
			</div>
		}
	</div>
	<div
		[ngClass]="[
			position === 'left-aligned' || position === 'top-left-aligned'
				? 'mt-1 w-full sm:mt-0 ' + contentWidth
				: 'mt-1',
			position === 'aligned-space-between' ? '!mt-0' : '',
			mobilePosition === 'left-aligned' ||
			mobilePosition === 'top-left-aligned'
				? 'mt-0'
				: 'mt-1',
		]"
	>
		<ng-content />
	</div>
</ng-template>
