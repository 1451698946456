<div
	*ngrxLet="isMobile$ as isMobile"
	[attr.aria-labelledby]="triggerId + '-header'"
	aria-modal="true"
	data-test-id="sheet-container"
	role="dialog"
>
	<ng-content />
	<div
		#sheetContentWrapper
		[class]="className + ' ' + sheetWidthClass + ' ' + sheetAlignmentClass"
		[ngClass]="{
			'inset-x-0 h-auto !max-h-[calc(100vh-180px)] !w-full border-t':
				isMobile,
			'!top-auto bottom-0': showSheet && isMobile,
			'-bottom-[100%] top-auto': !showSheet && isMobile,
			'h-full': showSheet && !isMobile,
			'-right-[50%]':
				!showSheet && !isMobile && sheetAlignment === 'right',
			'right-0 border-l':
				showSheet && !isMobile && sheetAlignment === 'right',
			'-left-[50%]': !showSheet && !isMobile && sheetAlignment === 'left',
			'left-0 border-r':
				showSheet && !isMobile && sheetAlignment === 'left',
		}"
		class="fixed top-0 z-50 flex flex-col border-ui-100 bg-white p-4 transition-sheet duration-500 sm:p-6"
		data-test-id="sheet-content"
	>
		<div #sheetHeader class="flex flex-col">
			<button
				(click)="toggle()"
				class="text-link flex self-end text-sm sm:text-base"
				data-test-id="sheet-close-button"
				type="button"
			>
				{{ 'global.close' | translate }}
			</button>
			<div [attr.id]="triggerId + '-header'" data-test-id="sheet-header">
				<ng-content select="vp-sheet-header" />
			</div>
		</div>
		@if (!isLoading) {
			<ng-content
				data-test-id="sheet-content"
				select="vp-sheet-content"
			/>
		}
		@if (isLoading) {
			<div
				class="flex h-10 items-center justify-center"
				data-test-id="sheet-loading-spinner"
			>
				<svg-icon
					class="block size-10 animate-spin text-black"
					src="assets/icons/icon-spinner.svg"
				/>
			</div>
		}
		<button (click)="toggle()" class="sr-only" type="button">
			{{ 'global.close' | translate }}
		</button>
	</div>
	<button
		[attr.aria-label]="'global.close' | translate"
		[ngClass]="{
			'overlay-dark-3 fixed inset-x-0 top-0 z-20 size-full': showSheet,
			'invisible hidden': !showSheet,
		}"
		(click)="hide()"
		class="transition-colors"
		tabindex="-1"
	></button>
</div>
