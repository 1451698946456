import { Directive } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { cn } from '@valk-nx/core/lib/core';

@Directive()
export abstract class BaseDirective {
	language: string;

	cn = cn;

	constructor(public translate: TranslateService) {
		this.language =
			this.translate?.currentLang || this.translate?.defaultLang;
	}
}
