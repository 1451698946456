import plugin from 'tailwindcss/plugin';

const headingGroup = plugin.withOptions(function () {
	return function ({ addComponents, e, theme }) {
		const variants = {
			sm: {
				heading: `var(--heading-group-sm-font-size, ${theme('fontSize.xl')})`,
				overline: `var(--heading-group-sm-overline-font-size, ${theme(
					'fontSize.xs',
				)})`,
			},
			md: {
				heading: `var(--heading-group-md-font-size, ${theme('fontSize.2xl')})`,
				overline: `var(--heading-group-md-overline-font-size, ${theme(
					'fontSize.sm',
				)})`,
			},
			lg: {
				heading: `var(--heading-group-lg-font-size, ${theme('fontSize.3xl')})`,
				overline: `var(--heading-group-lg-overline-font-size, ${theme(
					'fontSize.base',
				)})`,
			},
		};

		addComponents({
			'header:has(.heading-group)': {
				display: 'flex',
				flexDirection: 'column-reverse',
			},
			[`.${e('heading-group')}`]: {
				color: `var(--heading-group-color-text, ${theme('textColor.dark.primary')})`,
				fontSize: variants.sm.heading,
				fontWeight: `var(--heading-group-font-weight, ${theme('fontWeight.semibold')})`,
				lineHeight: `var(--heading-group-line-height, ${theme('lineHeight.tight')})`,
				marginBottom: 0,

				[`${e('strong')}, b`]: {
					color: `var(--heading-group-overline-color-text, ${theme('colors.accent.125')})`,
					fontWeight: `var(--heading-group-font-weight, ${theme('fontWeight.semibold')})`,
				},

				[`@media (min-width: ${theme('screens.md')})`]: {
					fontSize: variants.md.heading,
				},
			},
			[`.${e('heading-group-overline')}`]: {
				color: `var(--heading-group-overline-color-text, ${theme('colors.accent.125')})`,
				display: 'block',
				fontSize: variants.md.overline,
				fontWeight: theme('fontWeight.normal'),
				letterSpacing: theme('letterSpacing.widest'),
				lineHeight: `var(--heading-group-overline-line-height, ${theme('lineHeight.none')})`,
				marginBottom: `var(--heading-group-overline-margin-bottom, ${theme('spacing')['1.5']})`,
				textTransform: 'uppercase',
			},

			[`.${e('heading-group-section')}`]: {
				fontWeight: `var(--heading-group-section-font-weight, ${theme('fontWeight.semibold')})`,
				lineHeight: `var(--heading-group-section-line-height, ${theme('lineHeight.tight')})`,

				[`${e('strong')}, b`]: {
					fontWeight: `var(--heading-group-section-font-weight, ${theme('fontWeight.semibold')})`,
				},
			},
		});

		Object.keys(variants).forEach((key, index) => {
			const mobileKey = Object.keys(variants)[index - 1];
			addComponents({
				[`.${e('heading-group-' + key)}`]: {
					fontSize: mobileKey
						? variants[mobileKey].heading
						: `var(--heading-group-xs-font-size, ${theme('fontSize.lg')})`,

					[`@media (min-width: ${theme('screens.md')})`]: {
						fontSize: variants[key].heading,
					},
				},
				[`.${e('heading-group-' + key)} + .heading-group-overline`]: {
					fontSize: variants[key].overline,
				},
			});
		});
	};
});

export default headingGroup;
