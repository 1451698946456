/* istanbul ignore file */
import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: 'vp-sheet-content, [vp-sheet-content], [vpSheetContent]',
	standalone: false,
})
export class SheetContentDirective {
	constructor(readonly elementRef: ElementRef) {
		elementRef.nativeElement.classList.add(
			'overflow-y-scroll',
			'block',
			'will-change-scroll',
		);
	}
}

@Directive({
	selector: 'vp-sheet-header, [vp-sheet-header], [vpSheetHeader]',
	standalone: false,
})
export class SheetHeaderDirective {
	constructor(readonly elementRef: ElementRef) {
		elementRef.nativeElement.classList.add('flex-1', 'mb-2');
	}
}
