import { createAction, props } from '@ngrx/store';

import {
	AvailabilityWidgetInterface,
	DealsShowAvailabilityType,
} from './tag-manager.interface';

export const giftcardOrderB2B = createAction(
	'[TagManager] Giftcard Order Business to Business',
	props<{ emailAddress: string; phoneNumber: string; companyName: string }>(),
);

export const giftcardOrderB2C = createAction(
	'[TagManager] Giftcard Order Business to Consumer',
	props<{
		numCards: number;
		orderValue: number;
		emailAddress: string;
		phoneNumber: string;
	}>(),
);

export const formSubmit = createAction(
	'[TagManager] Form submit',
	props<{ formSubject: string }>(),
);

export const newsletterSubmit = createAction(
	'[TagManager] Newsletter submit',
	props<{ emailAddress: string }>(),
);

export const dealsShowAvailability = createAction(
	'[TagManager] Deals show availability',
	props<DealsShowAvailabilityType>(),
);

export const quoteRequest = createAction(
	'[TagManager] Quote request form',
	props<{ emailAddress: string; phoneNumber: string; companyName: string }>(),
);

export const availabilityWidget = createAction(
	'[TagManager] Availability widget CTA',
	props<AvailabilityWidgetInterface>(),
);
