import { Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-stepper',
	template: `
		<vp-stepper
			[ariaDecreaseLabel]="to['ariaDecreaseLabel'] | translate"
			[ariaIncreaseLabel]="to['ariaIncreaseLabel'] | translate"
			[formControl]="formControl"
			[max]="to.max"
			[min]="to.min"
			[readonly]="to.readonly"
			[value]="formControl.value || 0"
			(valueChange)="updateValue($event)"
			ngDefaultControl
		/>
	`,
	standalone: false,
})
export class StepperComponent extends FieldType<FieldTypeConfig> {
	updateValue(value: number): void {
		this.formControl.patchValue(value);
	}
}
