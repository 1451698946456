import {
	ChangeDetectionStrategy,
	Component,
	computed,
	Input,
	input,
	ViewEncapsulation,
} from '@angular/core';

import { fallbackLanguage, Language } from '@valk-nx/core/lib/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	selector: 'vp-recaptcha-notification',
	templateUrl: './recaptcha-notification.html',
})
export class RecaptchaNotificationComponent {
	@Input() className = '';

	currentLanguage = input<Language>(fallbackLanguage);

	translation = computed<string>(() => {
		return (
			this.translations.get(this.currentLanguage()) ||
			this.translations.get(fallbackLanguage) ||
			''
		);
	});

	translations = new Map<Language, string>([
		[
			'de',
			'Diese Website ist durch reCAPTCHA geschützt und es gelten die <a href="https://policies.google.com/privacy" target="_blank">Datenschutzbestimmungen</a> und <a href="https://policies.google.com/terms" target="_blank">Nutzungsbedingungen</a> von Google.',
		],
		[
			'en',
			'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.',
		],
		[
			'fr',
			"Ce site est protégé par reCAPTCHA et  <a href='https://policies.google.com/privacy' target='_blank'>la politique de confidentialité</a> et <a href='https://policies.google.com/terms' target='_blank'>les conditions d'utilisation</a> de Google s'appliquent.",
		],
		[
			'nl',
			'Deze site wordt beschermd door reCAPTCHA en het <a href="https://policies.google.com/privacy" target="_blank">privacybeleid</a> en de <a href="https://policies.google.com/terms" target="_blank">servicevoorwaarden</a> van Google zijn van toepassing.',
		],
		[
			'es',
			'Este sitio está protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy" target="_blank">Política de privacidad</a> y las <a href="https://policies.google.com/terms" target="_blank">Condiciones de servicio</a> de Google.',
		],
	]);
}
