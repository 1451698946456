/* stylelint-disable selector-max-compound-selectors */

.show {
  @apply mx-0;
}

.dropdown {
  &.ng-select {
    .ng-option-label {
      color: var(--inputs-color-text, theme('textColor.dark.primary'));
    }

    .ng-select-container {
      border-width: var(--inputs-border-width, theme('borderWidth.DEFAULT'));
      border-color: var(--inputs-color-border, theme('colors.ui.100'));
      border-radius: var(--inputs-border-radius, theme('borderRadius.DEFAULT'));

      @apply h-11 cursor-pointer;

      &:hover {
        border-color: var(--inputs-color-border-hover, theme('colors.ui.125'));
      }

      &.ng-has-value .ng-placeholder {
        @apply hidden;
      }

      .ng-value-container {
        @apply items-center;

        .ng-value .icon {
          @apply hidden;
        }

        .ng-placeholder,
        .ng-value,
        .ng-input {
          color: var(--inputs-color-text-placeholder, theme('textColor.dark.secondary'));

          @apply px-4 ;
        }

        .ng-value .ng-value-label:nth-of-type(2) {
          @apply hidden;
        }
      }
    }

    .ng-arrow-wrapper {
      @apply flex items-center mr-4;

      .ng-arrow {
        background-image: url('https://assets.vandervalkonline.com/live/assets/icons/icon-chevron-down.svg');

        @apply w-5 h-5;
      }
    }

    .ng-dropdown-panel {
      border-width: var(--inputs-border-width, theme('borderWidth.DEFAULT'));
      border-color: var(--inputs-color-border, theme('colors.ui.100'));
      border-radius: var(--inputs-border-radius, theme('borderRadius.DEFAULT'));

      @apply overflow-hidden;

      .ng-dropdown-panel-items {
        .ng-option {
          @apply px-4 py-3 relative;

          &:hover {
            background-color: theme('colors.select.75') !important;
          }

          &.ng-option-marked {
            @apply bg-select-50;
          }

          &.ng-option-selected {
            @apply bg-select-75;
          }
        }
      }

      &.ng-select-top {
        @apply bottom-11;
      }
    }

    &.integrated {
      .ng-select-container {
        @apply bg-ui-25;
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        @apply rounded-b-none elevation-3;
      }

      .ng-dropdown-panel {
        @apply rounded-t-none border-t-0 bg-white;

        .ng-dropdown-panel-items {
          @apply elevation-3;
        }
      }

      .ng-arrow-wrapper .ng-arrow {
        background-image: url('https://assets.vandervalkonline.com/live/assets/icons/icon-chevron-up.svg');
      }
    }

    &.detached {
      .ng-select-container {
        @apply bg-none border-none elevation-none h-6;

        .ng-placeholder,
        .ng-value {
          @apply px-0 text-dark-primary z-10 relative w-full;
        }

        .ng-arrow-wrapper {
          @apply mr-0;

          .ng-arrow {
            @apply bg-none bg-black bg-opacity-75 w-4 h-4 ml-2;

            mask-image: url('https://assets.vandervalkonline.com/live/assets/icons/icon-chevron-down.svg');
            mask-position: center;
          }
        }
      }

      .ng-dropdown-panel {
        @apply border-t rounded-t mt-2 min-w-max;
      }

      &.ng-select-opened {
        .ng-select-container {
          @apply elevation-none;
        }

        .ng-arrow-wrapper {
          .ng-arrow {
            mask-image: url('https://assets.vandervalkonline.com/live/assets/icons/icon-chevron-up.svg');
          }
        }
      }

      &.centered {
        .ng-dropdown-panel {
          @apply left-2/4 -translate-x-1/2;
        }
      }
    }

    &.ng-select-opened.ng-select-top {
      .ng-select-container {
        @apply rounded-b-md rounded-t-none elevation-3;
      }

      .ng-dropdown-panel {
        @apply rounded-t-md rounded-b-none bg-white;

        .ng-dropdown-panel-items {
          @apply elevation-none;
        }
      }
    }

    &.error:not(.detached) {
      .ng-select-container,
      .ng-dropdown-panel {
        @apply border-error-100;
      }
    }

    &.attached.ng-select-opened {
      .ng-select-container {
        @apply z-[unset];

        &::after {
          @apply absolute content-empty bottom-px left-0 block w-full h-2 bg-white z-[9999];
        }
      }
    }

    &.integrated.ng-select-opened {
      .ng-select-container {
        @apply bg-white z-[unset];

        &::after {
          @apply absolute content-empty bottom-0 left-0 block w-full h-2 bg-white z-[9999];
        }
      }

      &:not(.ng-select-top) {
        .ng-select-container {
          border-bottom: 0 !important;
        }
      }

      &.ng-select-top {
        .ng-select-container {
          border-top: 0 !important;
        }
      }
    }

    &.ng-select.ng-select-focused.ng-select-single {
      .ng-select-container {
        @apply shadow-focus-100 shadow-item-focus border-select-100;
      }

      .ng-dropdown-panel {
        @apply border-select-100;

        .ng-dropdown-panel-items {
          @apply shadow-focus-100 shadow-item-focus;
        }
      }

      &.detached {
        .ng-dropdown-panel {
          border-radius: var(--inputs-border-radius, theme('borderRadius.DEFAULT'));
          
          @apply border-ui-100;

          .ng-dropdown-panel-items {
            @apply shadow-none;
          }
        }
      }
    }

    &.ng-select-opened.searchable {
      .ng-arrow-wrapper .ng-arrow {
        background-image: url('https://assets.vandervalkonline.com/live/assets/icons/icon-looking-glass.svg');
      }
    }
  }
}

.dropdown-white {
  &.ng-select {
    &.detached {
      .ng-select-container {
        .ng-placeholder,
        .ng-value {
          @apply  text-white;
        }

        .ng-arrow-wrapper {
          @apply mr-0;

          .ng-arrow {
            @apply bg-white bg-opacity-75;
          }
        }
      }
    }
  }
}
